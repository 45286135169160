import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { loadModels } from '../common'


const UserInfo = (props) => {

    const {userid} = props 

    const apiModel = 'user'
    const [userLoaded,setUserLoaded] = useState(false)
    const [profileCompleted,setProfileCompleted] = useState(false)
    const user = useSelector((state)=> state.user)

    useEffect(()=>{
        setUserLoaded(false)
        setProfileCompleted(false)
        if(userid){
            loadModels(process.env.REACT_APP_LOCAL_AUTH_URL,apiModel,userid,user.token).then((result) => {
                if(result && result.result){
                    let usr = result.result
                    for(let k in usr){
                        for(let j in usr[k]){
                            if(j === 'profile_completed' && usr[k][j]===true){
                                setProfileCompleted(true)
                            }
                        }
                    }
                    setUserLoaded(true)
                } else {
                    if(result.error){
                        console.log('Impossibile caricare il profilo venditore: '+result.error)
                        toast.error('Impossibile caricare il profilo venditore: '+result.error)
                    }
                }
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userid, user.token])

    return (
        <>
        <div className='border border-even rounded ps-2 pt-2'>
            <h5 className='text-even'>Utente con profilo completato: <span style={{textDecoration: 'underline'}}>{userLoaded ? (profileCompleted ? 'Sì' : 'No') : 'N/D'}</span></h5>
        </div>
        </>
    )
}

export default UserInfo

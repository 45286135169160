import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { get_autocomplete_request_body, get_origin_url } from '../helper.js'
import axios from 'axios';

const AutocompleteSubMultiSelect = (props) => {

    const {apiSubModel,singleChangeHandler,autoCompleteClass,makeDisable,baseUrl,field} = props
    const [displayValue, setDisplayValue] = useState({'memo': ''})
    const [options, setOptions] = useState([])
    const [searchString,setSearchString] = useState(null)
    const [triggerSearch,setTriggerSearch] = useState(1)
    const user = useSelector((state)=> state.user)
    const dataStructure = useSelector((state)=> state.dataStructure)
    const ref = useRef()

  const handleInputChange = (event) => {
    if(event){
      setOptions([])
      let newValue = event.target.value
      if(newValue===''){
        singleChangeHandler('')
      }
      setSearchString(newValue)
      if(newValue === searchString) setTriggerSearch((triggerSearch)=> triggerSearch + 1)
    }
  }

  useEffect(()=>{
    const searchData = setTimeout(async()=>{

      try {
          const url = (field.origin ? get_origin_url(field,dataStructure) : (baseUrl ? baseUrl : process.env.REACT_APP_LOCAL_BACKEND_URL)) + apiSubModel + '/search'
          const config = {
                  headers: {'Authorization': 'Bearer ' + user.token},
                  params: {}
              }
          let requestBody=get_autocomplete_request_body(searchString,searchString?searchString.length:0)
          const response = await axios.post(url,requestBody,config)
          setOptions(response.data.result) // Assumendo che l'API restituisca un array di oggetti { id, displayname }
      } catch (error) {
          console.error('Errore nel recupero delle opzioni:', error);
      }
    
    },500)
    return () => clearTimeout(searchData)
  },[apiSubModel, baseUrl, dataStructure, field, searchString, user.token, triggerSearch])


  const handleOptionSelect = (event, newValue) => {
    if (newValue) {
      setDisplayValue({'memo': null})
      singleChangeHandler(newValue.uuid)
    }
  };

  return (
    <div>
      <Autocomplete ref={ref}
        options={options}
        className={makeDisable?'autocomplete-disable mb-2':'mb-2'}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.uuid}>
              {option.memo}
            </li>
          );
        }}
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.memo?option.memo:''}
        value={displayValue}
        onChange={handleOptionSelect}
        onInputChange={handleInputChange}
        onFocus={handleInputChange}
        freeSolo={true}
        disabled={makeDisable}
        renderInput={(params) => (
          <TextField key={params.uuid} className={autoCompleteClass}
            {...params}
            placeholder="Cerca un elemento..."
            variant="standard"
          />
        )}
      />
    </div>
  );
};

export default AutocompleteSubMultiSelect

import React, {useState, useCallback, /* useMemo, */ useEffect, useRef} from 'react'
import { AgGridReact } from 'ag-grid-react' // AG Grid Component
import "ag-grid-community/styles/ag-grid.css" // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css" // Optional Theme applied to the grid
import { useDispatch, useSelector } from 'react-redux'
import { setDataStructure, setUiStructure, setData, setPagination, setLoadedCount, increaseLoadedCount, setRecordCount, setPageCount, setCurrentPage, decreaseCurrentPage, increaseCurrentPage, setrecordsPerPage, setJsonMaxSize, setServiceCode, setRelatedUrls } from '../app/store'
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom'
import { convertTimestampToReadable, convertDateOnlyToReadable, convertTimeOnlyToReadable, get_backend_filter, get_backend_sort_model, get_error_message_by_code } from '../helper'
import { hiddenFields, numberFields, timeFields, paginationOptions, tableAdditionalHiddenFields } from '../constants'
import useWindowSize from '../hooks/Windowsize'
import { LOG_URL } from '../constants'

const TableComponent = (props) => {

    const {nomePlur,url,apiModel,triggerReset,freeSearch,baseUrl,additionalFilter,disableRowClick} = props
    const [fs,setFontSize] = useState('16px')
    const [gridApi,setGridApi] = useState(null)
    const [scrollIndexState,setScrollIndex] = useState(0)
    const loadedCount = useSelector((state)=> state.loadedCount)
    const navigate = useNavigate()
    const windowSize = useWindowSize()
    const menu = useSelector((state)=> state.menu)
    const pagination = useSelector((state)=> state.pagination)
    const data = useSelector((state)=> state.data)
    const dataStructure = useSelector((state)=> state.dataStructure)
    const user = useSelector((state)=> state.user)
    const dispatch = useDispatch()
    const selectRef = useRef()
    const {id} = useParams()
    let scrollAndPagRecordEnabled = useRef(false)

    // Load data structure (schema) FIRST LOADING
    useEffect(() => {
        scrollAndPagRecordEnabled.current=false

        // Restore previous saved font dimension
        setFontSize(localStorage.getItem(apiModel+'_fontSize')?localStorage.getItem(apiModel+'_fontSize'):'16px')

        const getStrData = async () => {
            const url = baseUrl ? baseUrl + 'manifest.json' : process.env.REACT_APP_LOCAL_BACKEND_URL + 'manifest.json'
            const config = {
            headers: { 'Authorization': 'Bearer ' + user.token },
            params: {}
            }
            try {
            const response = await axios.get(url, config)
            const recdata = response.data
            if (recdata) {
                let servicecode = recdata.code
                    dispatch(setServiceCode(servicecode))
                let modeldata = recdata.schema[apiModel].fields
                let uidata = []
                let tempuidata = recdata.schema[apiModel].ui
		if(tempuidata);else uidata=tempuidata;
                for (let k in tempuidata) {
                    if(nomePlur !== 'ordini' && tempuidata[k].name === 'Completamento ordine'){
                        // Don't add the section to the ui structure
                    } else {
                        uidata.push(tempuidata[k])
                    }
                }
                let mydata = [];
                for (let k in modeldata) {
                    mydata.push({
                        name: k, label: (typeof modeldata[k].name === 'undefined'?k: (typeof modeldata[k].name === 'string' ? modeldata[k].name : modeldata[k].name.it)),
                        type: modeldata[k].type,
                        model: modeldata[k].model,
                        origin: modeldata[k].origin,
                        dependent: modeldata[k].dependent,
                        required: modeldata[k].required,
                        min: modeldata[k].min,
                        max: modeldata[k].max,
                        readonly: modeldata[k].readonly,
                    })
                }
                dispatch(setDataStructure(mydata))
                if(recdata.relations){
                    let urls = {}
                    for(let j in recdata.relations){
                        urls[j] = recdata.relations[j].substring(0,recdata.relations[j].indexOf('manifest.json'))
                    }
                    dispatch(setRelatedUrls(urls))
                }
                if(uidata && typeof uidata !== 'undefined'){
                    dispatch(setUiStructure(uidata))
                }else{
                    dispatch(setUiStructure(null))
                }

                // Set max JSON transmission dimension, necessary for file upload
                if(recdata.config && recdata.config.json_max){
                    dispatch(setJsonMaxSize(recdata.config.json_max))
                }

                return mydata
            }
            } catch (error) {
                if(error.response.data.error.code){
                    console.log(get_error_message_by_code(error.response.data.error.code))
                    toast.error(get_error_message_by_code(error.response.data.error.code))
                } else {
                    console.log(error)
                    toast.error('Errore nel caricamento della struttura modello')
                }
            }
        }
        getStrData().then(
            (mydata)=>{
                let defaultColumnState = []
                for (let k in mydata){
                    defaultColumnState.push({
                        colId: mydata[k].name,sort: null,
                        width: 200,
                        rowGroup: null,
                        pivot: null,
                        pinned: null
                    })
                }
                if(gridApi){
                    // Save datastructure for this apiModel in local storage
                    localStorage.setItem(apiModel+'_dataStructure', JSON.stringify(mydata).length)

                    // Save original column state if not present to allow the user to restore it via reset
                    if(!localStorage.getItem(apiModel+'_columnState')){
                        localStorage.setItem(apiModel+'_originalColumnState', JSON.stringify(/* gridApi.getColumnState() */ defaultColumnState))
                    }

                    const freeSearchString = localStorage.getItem(apiModel+'_freeSearchString')
                    const inState = localStorage.getItem(apiModel+'_initialState')!=="undefined" ? JSON.parse(localStorage.getItem(apiModel+'_initialState')) : {}
                    const columnState = JSON.parse(localStorage.getItem(apiModel+'_columnState'))

                    const filterModel=(inState&&inState.filter)?inState.filter.filterModel:{}
                    const sortBy=get_backend_sort_model(columnState)

                    getFilteredData(filterModel,sortBy,freeSearchString,mydata).then(
                        ()=>{
                            setTimeout(()=>{
                                scrollAndPagRecordEnabled.current=true
                                setScrollIndex(localStorage.getItem(apiModel+'_scrollIndex')?parseInt(localStorage.getItem(apiModel+'_scrollIndex')):0)
                            },1000)
                        }
                    )
                    if(inState && inState.filter){
                        gridApi.setFilterModel(inState.filter.filterModel)
                    }else{
                        gridApi.setFilterModel({})
                    }
                    if(columnState && columnState.length){
                        gridApi.applyColumnState({state: columnState,applyOrder: true})
                    } else {
                        gridApi.applyColumnState({state: defaultColumnState,applyOrder: true})
                    }
                }
            }
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },[apiModel,dispatch,gridApi,additionalFilter]
    )

    useEffect(()=>{
        if(gridApi && scrollAndPagRecordEnabled.current){
            gridApi.ensureIndexVisible(scrollIndexState,'top')
        }
    },[scrollIndexState,gridApi])

    // Reset filters and columns if parent component command so by increasing triggerReset variable
    // No need to call axios data get method since it's done in parent component
    useEffect(()=>{
        if(triggerReset){
            if(gridApi) {
                gridApi.setFilterModel({})
                localStorage.setItem(apiModel+'_initialState','{}')
                const originalColState = JSON.parse(localStorage.getItem(apiModel+'_originalColumnState'))
                if(originalColState){
                    gridApi.applyColumnState({state: originalColState,applyOrder: true})
                }
                // Save as standard column state even so that reset is not lost on reload if another standard column state is already present
                localStorage.setItem(apiModel+'_columnState','[]')
                gridApi.ensureIndexVisible(0, "top")
                setScrollIndex(0)
                localStorage.setItem(apiModel+'_scrollIndex', 0)
                dispatch(setrecordsPerPage(200))
                if(pagination.currentPage!==1){
                    dispatch(setCurrentPage(1))
                    let tmpPagination = {
                        ...pagination,
                        currentPage: 1
                    }
                    localStorage.setItem(apiModel+'_pagination', JSON.stringify(tmpPagination))

                } else {
                    getFilteredData({},{},freeSearch,dataStructure.structure)
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[triggerReset,gridApi,dispatch])

    const customValueFormatter = (fieldname,fieldtype,p) => {
        if (fieldname==='price' && !isNaN(p.value)) {
            return (Math.round(p.value*100)/100).toLocaleString('it-IT', {style: "currency", currency: "EUR"})
        }
        if (timeFields.includes(fieldtype) && !isNaN(p.value)) {
            if(fieldtype==='datetime'){
                return convertTimestampToReadable(p.value)
            }
            if(fieldtype==='date'){
                return convertDateOnlyToReadable(p.value)
            }
            if(fieldtype==='time'||fieldtype==='timerange'){
                return convertTimeOnlyToReadable(p.value)
            }
        }
        return p.value
    }

    const handleSizeUp = () => {
        let curFs=fs.split('px')[0];
        let curNum=parseInt(curFs);
        if(!isNaN(curNum)){
            let newNum=curNum+2;
            setFontSize(newNum+'px');
            localStorage.setItem(apiModel+'_fontSize',newNum+'px')
        }
    }
    const handleSizeDown = () => {
        let curFs=fs.split('px')[0];
        let curNum=parseInt(curFs);
        if(!isNaN(curNum) && curNum>=8){
            let newNum=curNum-2;
            setFontSize(newNum+'px');
            localStorage.setItem(apiModel+'_fontSize',newNum+'px')
        }
    }

    const getFilterType = (fieldtype) => {
        if (timeFields.includes(fieldtype)) return 'agDateColumnFilter'
        return true
    } 

    const getFilterParams = (fieldtype) => {
        let filterParams = {}
        if (numberFields.includes(fieldtype) || timeFields.includes(fieldtype)) {
            filterParams.filterOptions = [
                {
                    displayKey: 'eq',
                    displayName: 'Uguale a',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'gt',
                    displayName: 'Maggiore di',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'gte',
                    displayName: 'Maggiore o uguale a',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'lt',
                    displayName: 'Minore di',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'lte',
                    displayName: 'Minore o uguale a',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'inRange_b',
                    displayName: 'Nell\'intervallo',
                    predicate: ([fv1, fv2], cellValue) => true,
                    numberOfInputs: 2
                },
            ]
        } else if(!['boolean','uuid','uuid[]'].includes(fieldtype)) {
            filterParams.filterOptions = [
                {
                    displayKey: 'contains_b',
                    displayName: 'Contiene',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'neq',
                    displayName: 'Non equivale a',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'startswith',
                    displayName: 'Comincia per',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'endswith',
                    displayName: 'Finisce per',
                    predicate: ([filterValue], cellValue) => true
                },
                {
                    displayKey: 'eq',
                    displayName: 'Stringa esatta',
                    predicate: ([filterValue], cellValue) => true
                },
                
            ]
        } else if (['uuid','uuid[]'].includes(fieldtype)) {
            filterParams.filterOptions = [
                {
                    displayKey: 'contains_b',
                    displayName: 'Contiene',
                    predicate: ([filterValue], cellValue) => true
                }
            ]
        }
        filterParams.debounceMs = 200
        filterParams.maxNumConditions = 1
        return filterParams
    }

    // Column Definitions: Defines the columns to be displayed (will be updated not via this useState, but via setGridOptions)
    const [colDefs] = useState([])

    useEffect(()=>{
        if(gridApi && dataStructure.structure){
            let newPreColDefs=[];
            for (const key in dataStructure.structure) {
                let fieldname = dataStructure.structure[key].name
                let fieldlabel = dataStructure.structure[key].label
                let fieldtype = dataStructure.structure[key].type
                newPreColDefs.push({
                    field: fieldname,
                    cellDataType: fieldtype==='boolean'?'boolean':'', // 'text', 'number', 'boolean', 'date', 'dateString' and 'object'
                    headerName: fieldlabel?fieldlabel.charAt(0).toUpperCase() + fieldlabel.slice(1):fieldname.charAt(0).toUpperCase() + fieldname.slice(1),
                    hide: (
                        hiddenFields.includes(fieldname) ||
                        tableAdditionalHiddenFields.includes(fieldname) ||
                        (nomePlur !== 'ordini' && fieldname==='order_completed') ||
                        (disableRowClick && fieldname==='user') ||
                        (disableRowClick && fieldname==='ecommerce_product_id') ||
                        (disableRowClick && fieldname==='ecommerce_buyer_id') ||
                        (disableRowClick && fieldname==='ecommerce_shipment_id') ||
                        (disableRowClick && fieldname==='offerstatus') ||
                        (disableRowClick && fieldname==='winevalue_origin') ||
                        (disableRowClick && fieldname==='seller_firstname') ||
                        (disableRowClick && fieldname==='seller_lastname') ||
                        (disableRowClick && fieldname==='buyer_firstname') ||
                        (disableRowClick && fieldname==='buyer_lastname') ||
                        (disableRowClick && fieldname==='mail_new_sent') ||
                        (disableRowClick && fieldname==='mail_published_sent') ||
                        (disableRowClick && fieldname==='mail_sold_sent') ||
                        (disableRowClick && fieldname==='mail_cancelled_sent') ||
                        (disableRowClick && fieldname==='mail_reminded_on_day')
                        // disableRowClick used inside Offerwizard for seller user
                    ) ? true : false,
                    cellClass: (numberFields.includes(fieldtype)) ? 'ag-right-aligned-cell' : '',
                    valueFormatter: p => customValueFormatter(fieldname,fieldtype,p),
                    filter: getFilterType(fieldtype),
                    filterParams: getFilterParams(fieldtype)
                });
            }
            gridApi.setGridOption("columnDefs", newPreColDefs)
        }
    },[dataStructure.structure, disableRowClick, gridApi, user.capabilities, nomePlur])
    
    // AG GRID EVENTS
    const onGridReady = (params) => {
        setGridApi(params.api)
    }

    // If needed, this event is available
    const onFirstDataRendered = (params) => {
    }

    const handleRowClicked = (event) => {
        if(scrollAndPagRecordEnabled.current && !disableRowClick) { // Prevents opening edit view on a record of an old table if data refresh is slow
            const clickedId = event.data.uuid
            const editurl = process.env.REACT_APP_LOCAL_SUBFOLDER+url+'/'+clickedId
            navigate(editurl)
        }
    }

    const onColumnMovedOrResized = (event) => {
        if(event.source==='uiColumnMoved'||event.source==='uiColumnResized') {
            const columnState = JSON.stringify(event.api.getColumnState())
            localStorage.setItem(apiModel+'_columnState', columnState)
        }
    }

    const handleFilterChanged = (event) => {
        // Ottieni i valori dei filtri
        const filterModel = event.api.getFilterModel()
        // Ottieni l'eventuale ordinamento per colonna
        const columnState = JSON.stringify(event.api.getColumnState())
        const sortBy=get_backend_sort_model(columnState)
        // Le condizioni qui sotto sono per poter distinguere i filtri applicati alle colonne via api (primo caricamento) e quelli applicati dall'utente. 
        // Se i filtri e gli ordinamenti vengono applicati al primo caricamento, non si deve ripetere getFilteredData e scrollare in su
        if(event.source !== 'gridInitializing' && event.source !== 'columnFilter' && event.source !== 'api'){

            // Salva l'ordinamento per colonna se è frutto di un'azione dell'utente
            localStorage.setItem(apiModel+'_columnState', columnState)

            if(loadedCount.loadedCount < 3){
                dispatch(setLoadedCount(3))
            } else {
                dispatch(increaseLoadedCount())
            }
            // Actually load data
            if(pagination.currentPage !== 1){
                dispatch(setCurrentPage(1))
                let tmpPagination = {
                    ...pagination,
                    currentPage: 1
                }
                localStorage.setItem(apiModel+'_pagination', JSON.stringify(tmpPagination))
            } else {
                getFilteredData(filterModel,sortBy,freeSearch,dataStructure.structure).then(
                    ()=>{
                        setScrollIndex(0)
                    }
                )
            }
        }
        if(event.source === 'columnFilter'){ // Separate from condition above due to a legacy problem, keeping that way in case it returns
            // Salva il filtro in local storage (è contenuto nello stato)
            localStorage.setItem(apiModel+'_initialState',JSON.stringify(event.api.getState()))

            if(loadedCount.loadedCount < 3){
                if(disableRowClick){
                    setTimeout(()=>{dispatch(setLoadedCount(3))},2000)
                } else {
                    dispatch(setLoadedCount(3))
                }
                
            } else {
                if(disableRowClick){
                    setTimeout(()=>{dispatch(increaseLoadedCount())},2000)
                } else {
                    dispatch(increaseLoadedCount())
                }
            }
            // Actually load data
            if(pagination.currentPage !== 1){
                dispatch(setCurrentPage(1))
                let tmpPagination = {
                    ...pagination,
                    currentPage: 1
                }
                localStorage.setItem(apiModel+'_pagination', JSON.stringify(tmpPagination))
            } else {
                getFilteredData(filterModel,sortBy,freeSearch,dataStructure.structure).then(
                    ()=>{
                        setScrollIndex(0)
                    }
                )
            }
        }
    }

    // Save table scroll on user interact with table
    const handleScroll = (event) => {
        if(loadedCount.loadedCount > 0) {
        const scrollPosTop = event.top
            const scrollIndex = (Math.abs(Math.round(scrollPosTop/42)))
            setScrollIndex(scrollIndex)
            if(scrollAndPagRecordEnabled.current){
                localStorage.setItem(apiModel+'_scrollIndex', scrollIndex)
            }
        }
    }

    const getFilteredData = useCallback(async(filterModel,sortModel,freeSearchString,dataStructurex,recordsPerPage) => {

        let lastRecordsPerPage = recordsPerPage?recordsPerPage:pagination.recordsPerPage // Receiving this from caller if necessary to not have to wait asyncronous dispatch
        if(loadedCount.loadedCount === 0){
              // Get initial pagination config saved if present
            if(localStorage.getItem(apiModel+'_pagination')!=="undefined" && localStorage.getItem(apiModel+'_pagination')!==null){
                const storedPagination = JSON.parse(localStorage.getItem(apiModel+'_pagination'))
                dispatch(setPagination(storedPagination))
                lastRecordsPerPage = storedPagination.recordsPerPage
            } else {
                dispatch(setPagination({
                  recordCount: 0,
                  pageCount: 1,
                  currentPage: 1,
                  recordsPerPage: 200
                }))
                lastRecordsPerPage = 200
            }
            dispatch(setLoadedCount(1))
        }
        const url = baseUrl ? baseUrl + apiModel + '/search' : process.env.REACT_APP_LOCAL_BACKEND_URL + apiModel + '/search'
        const config = {
            headers: {'Authorization': 'Bearer ' + user.token},
            params: {}
        }
        let requestBodyNotPaginated=get_backend_filter(filterModel,sortModel,freeSearchString,dataStructurex,(baseUrl===LOG_URL),additionalFilter)
        let countRequestBody={
            ...requestBodyNotPaginated,
            format: 'count'
          }
        let requestBodyPaginated = {}
        try{
            //const count = await axios.post(url,countRequestBody,config)
            const count = await axios.post(url,countRequestBody,config)
            const numRecords = count.data.result
            if(typeof numRecords === 'number'){
                dispatch(setRecordCount(numRecords))
                dispatch(setPageCount(Math.ceil(numRecords/lastRecordsPerPage)))
            }
            let offset = pagination.currentPage>0 ? (pagination.currentPage-1)*pagination.recordsPerPage : 0
            requestBodyPaginated = {
                ...requestBodyNotPaginated,
                limit: lastRecordsPerPage,
                offset: offset
            }
            const response = await axios.post(url,requestBodyPaginated,config)
            const recdata = response.data.result
            if(typeof recdata === 'object'){
                dispatch(setData(recdata))
            }
        } catch(error){
            if(error.response.data.error.code){
                console.log(get_error_message_by_code(error.response.data.error.code))
                toast.error(get_error_message_by_code(error.response.data.error.code))
            } else {
                console.log(error)
                toast.error('Errore nel caricamento dei dati in tabella')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch,apiModel,pagination.currentPage,pagination.recordsPerPage,additionalFilter])

    // Pagination buttons functions
    const handleGoToFirstPage = () => {
        dispatch(setCurrentPage(1))
        let tmpPagination = {
            ...pagination,
            currentPage: 1
        }
        setScrollIndex(0)
        localStorage.setItem(apiModel+'_scrollIndex', 0)
        localStorage.setItem(apiModel+'_pagination', JSON.stringify(tmpPagination))
    }
    const handleGoToLastPage = () => {
        dispatch(setCurrentPage(pagination.pageCount))
        let tmpPagination = {
            ...pagination,
            currentPage: pagination.pageCount
        }
        setScrollIndex(0)
        localStorage.setItem(apiModel+'_scrollIndex', 0)
        localStorage.setItem(apiModel+'_pagination', JSON.stringify(tmpPagination))
    }
    const handleIncreasePage = () => {
        dispatch(increaseCurrentPage())
        let tmpPagination = {
            ...pagination,
            currentPage: (pagination.currentPage<pagination.pageCount)?(pagination.currentPage+1):(pagination.currentPage)
        }
        setScrollIndex(0)
        localStorage.setItem(apiModel+'_scrollIndex', 0)
        localStorage.setItem(apiModel+'_pagination', JSON.stringify(tmpPagination))
    }
    const handleDecreasePage = () => {
        dispatch(decreaseCurrentPage())
        let tmpPagination = {
            ...pagination,
            currentPage: (pagination.currentPage>1)?(pagination.currentPage-1):(pagination.currentPage)
        }
        setScrollIndex(0)
        localStorage.setItem(apiModel+'_scrollIndex', 0)
        localStorage.setItem(apiModel+'_pagination', JSON.stringify(tmpPagination))
    }
    const handleSetRecordsPerPage = (value) => {
        let newPageCount = Math.ceil(pagination.recordCount/value),
            absoluteScrollIndex=parseInt(localStorage.getItem(apiModel+'_scrollIndex'))+(pagination.currentPage-1)*pagination.recordsPerPage,
            newCurrentPage = Math.floor((absoluteScrollIndex+value)/value)
        dispatch(setrecordsPerPage(value))
        dispatch(setPageCount(newPageCount))
        
            if(newCurrentPage === pagination.currentPage){
                let filterModel = gridApi.getFilterModel(),
                    columnState = gridApi.getColumnState(),
                    sortBy = get_backend_sort_model(columnState)
                getFilteredData(filterModel,sortBy,freeSearch,dataStructure.structure,value).then(
                    ()=>{
                        setScrollIndex(absoluteScrollIndex-(value*(newCurrentPage-1)))
                    }
                )
            } else {
                setScrollIndex(absoluteScrollIndex-(value*(newCurrentPage-1)))
            }
        
        if(scrollAndPagRecordEnabled.current){
            localStorage.setItem(apiModel+'_scrollIndex', absoluteScrollIndex-(value*(newCurrentPage-1)))
        }
        dispatch(setCurrentPage(newCurrentPage))
        let tmpPagination = {
            ...pagination,
            recordsPerPage: value,
            pageCount: newPageCount,
            currentPage: newCurrentPage
        }
        localStorage.setItem(apiModel+'_pagination', JSON.stringify(tmpPagination))
    }

    useEffect(()=>{
        const selectElement = selectRef.current
        if (selectElement) selectElement.value = pagination.recordsPerPage
    },[pagination.recordsPerPage])

    // Retrieve new data on pagination change or free search string change
    useEffect(()=>{
        if(gridApi && localStorage.getItem(apiModel+'_dataStructure')){
            if(scrollAndPagRecordEnabled.current){
                let tmpPagination = {
                    ...pagination,
                    currentPage: pagination.currentPage
                }
                localStorage.setItem(apiModel+'_pagination', JSON.stringify(tmpPagination))
            }
            if(JSON.stringify(dataStructure.structure).length === parseInt(localStorage.getItem(apiModel+'_dataStructure'))){
                const inState = localStorage.getItem(apiModel+'_initialState')!=="undefined" ? JSON.parse(localStorage.getItem(apiModel+'_initialState')) : {}
                const columnState = JSON.parse(localStorage.getItem(apiModel+'_columnState'))
                const filterModel=(inState&&inState.filter)?inState.filter.filterModel:{}
                const sortBy=get_backend_sort_model(columnState)
                getFilteredData(filterModel,sortBy,freeSearch,dataStructure.structure).then(
                    ()=>{
                        setScrollIndex(0)
                    }
                )
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[gridApi,pagination.currentPage,apiModel,freeSearch,colDefs])

    useEffect(()=>{
        if(loadedCount.triggerReload && scrollAndPagRecordEnabled.current && gridApi){
            let filterModel = gridApi.getFilterModel(),
                columnState = gridApi.getColumnState(),
                sortBy = get_backend_sort_model(columnState)
            getFilteredData(filterModel,sortBy,freeSearch,dataStructure.structure)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[loadedCount.triggerReload,gridApi])
  
    return (
        <>
        <div
         className="ag-theme-quartz-dark ag-grid-table" // applying the grid theme
         style={{fontSize: fs}} // the grid will fill the width of the parent container
        >
          <AgGridReact
              rowData={data.tableData}
              //initialStatpe={getInitialState()}
              columnDefs={colDefs}
              onGridReady={onGridReady}
              onFirstDataRendered={onFirstDataRendered}
              onColumnMoved={onColumnMovedOrResized}
              onColumnResized={onColumnMovedOrResized}
              onRowClicked={handleRowClicked}
              onFilterChanged={handleFilterChanged}
              onSortChanged={handleFilterChanged}
              onBodyScrollEnd={handleScroll}
              maintainColumnOrder={true}
              getRowId={(params) => {
                return params.data.uuid;
              }}
              
          />
        </div>
        <div className='row mt-1'>
            <div className='col-12 col-md-4'>
                {(windowSize!=='sm' || (!menu.show && windowSize==='sm')) && <><span className={'label' + (user.capabilities.includes('cap_admin')?' text-even':' text-bold')}>Dim. carattere: </span>
                <button type="button" className='btn mcr-btn btn-micro-with-icon' style={{padding: '4px 12px'}} onClick={handleSizeDown}><span className="material-symbols-rounded text-even">remove</span></button>
                <button type="button" className='btn mcr-btn btn-micro-with-icon ms-1' style={{padding: '4px 8px'}} onClick={handleSizeUp}><span className="material-symbols-rounded text-even">add</span></button></>}
            </div>
            <div className='col-12 mt-2 mt-md-0 col-md-8 d-flex justify-content-start justify-content-md-end'>
            {windowSize!=='sm' && <span className={'label shift-down me-2' + (user.capabilities.includes('cap_admin')?' text-even':' text-bold')}>Rec. per pagina:</span>}
            {windowSize==='sm' && <span className={'label me-2' + (user.capabilities.includes('cap_admin')?' text-even':' text-bold')}>Paginazione:</span>}
            {pagination.recordsPerPage && windowSize!=='sm' && <select name='recordperpage' ref={selectRef} onChange={(e)=>{handleSetRecordsPerPage(parseInt(e.target.value))}} defaultValue={pagination.recordsPerPage?pagination.recordsPerPage:null} className='form-select dark-select little-select d-inline-block me-2'>
                {
                    paginationOptions.map((option,index)=>(
                        <option key={index} value={option} >{option}</option>
                    ))
                }
            </select>}
                {<div className="btn-group" role="group" aria-label="Pulsanti di paginazione" style={id?{position: 'absolute',top: '82px',left: '110px'}:{}}>
                {pagination.pageCount>1 && <><button type="button" onClick={()=>{handleGoToFirstPage()}} className={"btn btn-sm btn-dark border-even" + (pagination.currentPage===1?" active":"")}>Prima</button>
                    <button type="button" onClick={()=>{handleDecreasePage()}} className={"btn btn-sm btn-dark border-even"}>{'<'}</button></>}

                    <button type="button" onClick={()=>{return false}} className={"btn btn-sm btn-dark border-even" + (pagination.currentPage!==1 && pagination.currentPage!==pagination.pageCount ?" active":"")}>
                        {pagination.currentPage + ' di '+(pagination.pageCount>=1?pagination.pageCount:1)}
                        </button>
                    
                    {pagination.pageCount>1 && <><button type="button" onClick={()=>{handleIncreasePage()}}className="btn btn-sm btn-dark border-even ">{'>'}</button>
                    <button type="button" onClick={()=>{handleGoToLastPage()}} className={"btn btn-sm btn-dark border-even" + (pagination.currentPage===pagination.pageCount?" active":"")}>Ultima</button></>}
                </div>}
            </div>
        </div>
        </>
      )
  
  }

export default TableComponent;

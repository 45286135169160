import React, { useCallback, useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { loadLoginFromLocalStorage, logIn, logOut, saveLoginInLocalStorage } from "./app/store";
import axios from 'axios';
import Home from './templates/Home';
import Redirector from './templates/Redirector';
import NotFound from './templates/404';
import ResetPasswordRequest from './templates/ResetPasswordRequest';
import ResetPasswordReset from './templates/ResetPasswordReset';
import { toast } from 'react-toastify';
import { MEDIA_URL, OFFER_URL, /* LOG_URL, */ refreshTokenIntervalDuration, tokenResidualValidityAllowed, offersFilter, ordersFilter }  from './constants'

const App = () => {

  const user = useSelector((state)=> state.user)
  const dispatch = useDispatch()
  
  /*                                 /
  /  Refresh login timer management  /
  /                                 */
  const refreshTokenInterval = useRef()

  /* Actual axios call to obtain a new token and refresh token */
  const renewToken = useCallback(async(refreshToken) => {
    const url = process.env.REACT_APP_LOCAL_AUTH_URL + 'session/token'
    const config = {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      params: {}
    }
    const postData = {
      'grant_type': 'refresh_token',
      'client_id': process.env.REACT_APP_CLIENT_ID,
      'refresh_token': refreshToken
    }
    try{
      const response = await axios.post(url,postData,config)
      if(response.data.error){
          toast.error(response.data.error)
          dispatch(logOut())
      } else {
        return response.data
      }
    } catch (error){
      toast.error('Errore di rete in fase di rinnovo credenziali di accesso, si prega di ripetere il login')
    }
    return null
  },[dispatch])

  const executeTokenCheck = useCallback(() => {
    let now = (new Date()).getTime(),
        expiration = (new Date(user.expires)).getTime()
    if((expiration !== 0) && (expiration-now < tokenResidualValidityAllowed*5)) {
      if(user.refreshToken){
        renewToken(user.refreshToken,user.expires).then(
          (data) => {
            if(data && data.access_token){
              dispatch(logIn(
                { token: data.access_token, refreshToken: data.refresh_token, expires: data.expires_in }
                ))
              dispatch(saveLoginInLocalStorage())
            } else {
              console.log('New token not received, will log out')
              toast.error('Errore in fase di rinnovo delle credenziali di accesso, si prega di ripetere il login')
              dispatch(logOut())
            }
          }
        )
      } else {
        dispatch(logOut())
      }
    }
  },[dispatch, renewToken, user.expires, user.refreshToken])

  /* Starts refresh token interval */
  const startRefreshTokenInterval = useCallback(() => {
      refreshTokenInterval.current = setInterval(() => {
        executeTokenCheck()
      }, refreshTokenIntervalDuration)
  },[executeTokenCheck])

  /* Clears refresh token interval */
  const clearRefreshTokenInterval = useCallback(() => {
    clearInterval(refreshTokenInterval.current);
  },[refreshTokenInterval])

  /* On login/logout/renew token, manage refresh token interval */
  useEffect(()=>{
    if(!refreshTokenInterval.current && user.logged_in) {
      startRefreshTokenInterval()
    }
    if(refreshTokenInterval.current && user.logged_in) {
      clearRefreshTokenInterval()
      startRefreshTokenInterval()
    }
    if(refreshTokenInterval.current && !user.logged_in){
      clearRefreshTokenInterval()
    }
  },[refreshTokenInterval,user.logged_in,startRefreshTokenInterval,clearRefreshTokenInterval])
  
  /* On login of page load from a previous logged in state, check token validity */
  useEffect(()=>{
    if(user.logged_in){
      let now = (new Date()).getTime(),
            expiration = (new Date(user.expires)).getTime()
      if((expiration === 0) || (expiration-now < tokenResidualValidityAllowed)) {
        dispatch(logOut())
      }
    }
  },[user.logged_in,dispatch,user.expires]);

  /*                                    /
  / End refresh login timer management  /
  /                                    */

  useEffect(()=>{
    if(!user.logged_in){
      document.body.classList.add('seller-bg')
      document.body.classList.remove('bg-dark')
      dispatch(loadLoginFromLocalStorage())
    } else {
      if(user.capabilities.includes('cap_admin')){
        document.body.classList.remove('seller-bg')
        document.body.classList.add('bg-dark')
      } else {
        document.body.classList.add('seller-bg')
        document.body.classList.remove('bg-dark')
      }
    }
  },[dispatch, user.capabilities, user.logged_in]);

  const page_config = [
    {nome_link:"Home",nome_icona:"home",url:'/dashboard',apiModel:null,nomeSing:null,nomePlur:null,enableSave:null,enableDelete:null,enableNew:null},
    {nome_link:"Registra utente",nome_icona:"person",url:'/register',apiModel:null,nomeSing:null,nomePlur:null,enableSave:null,enableDelete:null,enableNew:null},
    {nome_link:"Inserzioni",nome_icona:"sell",url:'/offers',apiModel:'offer',nomeSing:"inserzione",nomePlur:"inserzioni",baseUrl:OFFER_URL,additionalFilter:offersFilter,enableSave:true,enableDelete:true,enableNew:true},
    {nome_link:"Ordini",nome_icona:"shopping_cart",url:'/orders',apiModel:'offer',nomeSing:"ordine",nomePlur:"ordini",baseUrl:OFFER_URL,additionalFilter:ordersFilter,enableSave:true,enableDelete:false,enableNew:false},
    {nome_link:"Prodotti",nome_icona:"liquor",url:'/products',apiModel:'product',nomeSing:"prodotto",nomePlur:"prodotti",enableSave:false,enableDelete:false,enableNew:false},
    {nome_link:"Produttori",nome_icona:"person",url:'/brands',apiModel:'brand',nomeSing:"produttore",nomePlur:"produttori",enableSave:true,enableDelete:true,enableNew:false},
    {nome_link:"Categorie",nome_icona:"category",url:'/categories',apiModel:'category',nomeSing:"categoria",nomePlur:"categorie",enableSave:true,enableDelete:true,enableNew:false},
    {nome_link:"Tipi vino",nome_icona:"humidity_mid",url:'/wine_colors',apiModel:'wine_color',nomeSing:"tipo",nomePlur:"tipi",enableSave:true,enableDelete:true,enableNew:false},
    {nome_link:"Regioni",nome_icona:"globe",url:'/wine_regions',apiModel:'wine_region',nomeSing:"regione",nomePlur:"regioni",enableSave:true,enableDelete:true,enableNew:false},
    {nome_link:"Formati",nome_icona:"format_size",url:'/wine_formats',apiModel:'wine_format',nomeSing:"formato",nomePlur:"formati",enableSave:true,enableDelete:true,enableNew:false},
    {nome_link:"Casse",nome_icona:"package_2",url:'/wine_packs',apiModel:'wine_pack',nomeSing:"cassa",nomePlur:"casse",enableSave:true,enableDelete:true,enableNew:true},
    {nome_link:"Utenti",nome_icona:"person",url:'/users',apiModel:'user',nomeSing:"utente",nomePlur:"utenti",baseUrl:process.env.REACT_APP_LOCAL_AUTH_URL,enableSave:true,enableDelete:true,enableNew:true},
    /* {nome_link:"Logs",nome_icona:"warning",url:'/errors',apiModel:'log',nomeSing:"log",nomePlur:"log",baseUrl:LOG_URL,enableSave:false,enableDelete:false},
    {nome_link:"API Reference",nome_icona:"summarize",url:'/apireference',apiModel:'apiReference',nomePlur:"API Reference"} */
  ]
  
  /* Specific routing done in Centralbody */
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Redirector/>} />
        {
          user.capabilities.includes('cap_admin') && page_config.map((page,index) => {
            
            return <React.Fragment key={index}>
            <Route path={page.url} element={
              <Home url={page.url} apiModel={page.apiModel} nomeSing={page.nomeSing} nomePlur={page.nomePlur} enableSave={page.enableSave} enableDelete={page.enableDelete} enableNew={page.enableNew} links={page_config} baseUrl={page.baseUrl} additionalFilter={page.additionalFilter} />
            } />
            <Route path={page.url+'/:id'} element={
              <Home url={page.url} apiModel={page.apiModel} nomeSing={page.nomeSing} nomePlur={page.nomePlur} enableSave={page.enableSave} enableDelete={page.enableDelete} enableNew={page.enableNew} links={page_config} baseUrl={page.baseUrl} MEDIA_URL={MEDIA_URL} additionalFilter={page.additionalFilter} />
            } />
          </React.Fragment>
          })
      }
      {
        !user.capabilities.includes('cap_admin') && <>
          <Route path='/dashboard' element={<Home url={'/dashboard'} />}></Route>
          <Route path='/register' element={<Home url={'/register'}/>}></Route>
          <Route path='/confirmuser/:token' element={<Home url={'/confirmuser'}/>}></Route>
        </>
      }
        <Route path='/profile' element={<Home url={'/profile'} links={page_config} />}></Route>
        
        <Route path="/forgot-password/request" element={<ResetPasswordRequest/>} />
        <Route path="/forgot-password/reset/:token" element={<ResetPasswordReset/>} />
        <Route path="/forgot-password/reset" element={<ResetPasswordReset/>} />

        <Route path="*" element={<NotFound/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'

const Register = (props) => {

    const {owner} = props
    const registerUrl = process.env.REACT_APP_LOCAL_USER_URL + 'user/register'

    const initialUserState = {
        username: '',
        firstname: '',
        lastname: '',
        password: '',
        password2: '',
        adult: false,
        terms_and_conditions: false,
        telephone: '',
        role: owner === 'admin' ? '' : 'seller'
    }
    const [newUserData,setNewUserData] = useState(initialUserState)
    const [canSubmitUser,setCanSubmitUser] = useState(false)
    const [confirmRegistered,setConfirmRegistered] = useState(false)
    const initialValidationState = owner === 'admin' ? {
        'username':{valid: false,touched: false, message: 'Inserisci una mail valida'},
        'role':{valid: false,touched: false, message: 'Seleziona un ruolo per l\'utente'},
        'firstname': {valid: false,touched: false, message: 'Il nome è richiesto'},
        'lastname': {valid: false,touched: false, message: 'Il cognome è richiesto'},
        'password': {valid: false,touched: false, message: 'La password deve essere compresa fra 8 e 16 caratteri ed includere un numero e un simbolo scelto tra i seguenti: ! ? @ # $ % ^ & * -'},
        'password2': {valid: false,touched: false, message: 'Le due password non coincidono'},
        'adult': {valid: false,touched: false, message: 'Devi essere maggiorenne per registrarti'},
        'terms_and_conditions': {valid: false,touched: false, message: 'Devi accettare i termini e le condizioni per proseguire'},
        'telephone': {valid: false,touched: false, message: 'Devi inserire un cellulare italiano valido, il prefisso internazionale è opzionale'},
    } : {
        'username':{valid: false,touched: false, message: 'Inserisci una mail valida'},
        'firstname': {valid: false,touched: false, message: 'Il nome è richiesto'},
        'lastname': {valid: false,touched: false, message: 'Il cognome è richiesto'},
        'password': {valid: false,touched: false, message: 'La password deve essere compresa fra 8 e 16 caratteri ed includere un numero e un simbolo scelto tra i seguenti: ! ? @ # $ % ^ & * -'},
        'password2': {valid: false,touched: false, message: 'Le due password non coincidono'},
        'adult': {valid: false,touched: false, message: 'Devi essere maggiorenne per registrarti'},
        'terms_and_conditions': {valid: false,touched: false, message: 'Devi accettare i termini e le condizioni per proseguire'},
        'telephone': {valid: false,touched: false, message: 'Devi inserire un cellulare italiano valido, il prefisso internazionale è opzionale'},
    }
    const [validation, setValidation] = useState(initialValidationState)
    const [passwordInputType,setPasswordInputType] = useState('password')
    const navigate = useNavigate()

    const checkIfCanSubmitUser = () => {
        const checkValidationStatus = () => {
            for(let k in validation){
                if(!validation[k].valid) return false
            }
            return true
        }
        if(checkValidationStatus()){
            setCanSubmitUser(true)
        } else {
            setCanSubmitUser(false)
        }
    }

    const check_validation = (field) => {
        const check_single_field = (fieldName,value,checked) => {
            if(fieldName === 'username'){
                let rx = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
                if(rx.test(value)) return true
                return false
            } else if (fieldName === 'telephone'){
                let rx = /^((?:0039)?|(?:\+39)?)3[0-9]{8,9}$/
                if(rx.test(value)) return true
                return false
            } else if(fieldName === 'password'){
                let rx = /^(?=.*[0-9])(?=.*[!@#$%^&*?-])[a-zA-Z0-9!@#$%^&*?-]{8,16}$/
                if(rx.test(value)) return true
                return false
            } else if (fieldName === 'password2') {
                if(newUserData['password']===value) return true
                return false
            } else if (fieldName === 'adult') {
                return checked?true:false
            } else if (fieldName === 'terms_and_conditions') {
                return checked?true:false
            } else {
                if(value.length) return true
                return false
            }
        }
        let newVal = validation
        newVal[field.name].touched = true
        newVal[field.name].valid = check_single_field(field.name,field.value,field.checked)
        if(field.name === 'password'){
            // Check password2 field if modifying password field
            newVal['password2'].touched = true
            newVal['password2'].valid = check_single_field('password2',newUserData['password2'])
        }
        setValidation({...newVal})
        checkIfCanSubmitUser()
    }

    const handleUserChange = (event) => {
        let dataCopy = newUserData
        dataCopy[event.target.name]=event.target.value
        setNewUserData({...dataCopy})
        check_validation(event.target)
    }

    const cancelRegistration = () => {
        setNewUserData(initialUserState)
        navigate('/')
    }

    const handleSave = async (e) => {
        e.preventDefault()
        setCanSubmitUser(false)
        const url = registerUrl
        const postData = newUserData
        try{
            const response = await axios.post(url,postData)
            const recdata = response.data
                if (recdata) {
                    if(recdata.result){
                        setConfirmRegistered(true)
                    } else {
                        toast.error('Errore nella registrazione dell\'utente')
                    }
                }
        } catch (error) {
            if(error.response.data.error){
                console.log(error.response.data.error.description)
                toast.error(error.response.data.error.description)
            } else {
                console.log('Errore nella registrazione dell\'utente')
                toast.error('Errore nella registrazione dell\'utente')
            }
        }
    }

  return (<>
     <div className='container font-futura'>
     <ToastContainer
          className='toast-raised'
          position='bottom-right'
          theme='dark'
        />

        <div className={"modal custom-modal" + (confirmRegistered ? "" : " fade")} id="deleteModal" tabIndex="-1" aria-labelledby="deleteModalLabel" aria-hidden="false">
          <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content wl-form text-white shadow">
                  <div className="modal-header justify-content-center">
                      <h5 className="modal-title">Registrazione conclusa con successo!</h5>
                  </div>
                  <div className="modal-body text-center">
                  <Link className='even-link d-block mb-5' to='/'><span className="material-symbols-rounded text-even d-block mt-5">home</span>
                      <h6 className='h6'>Clicca sul link che ti abbiamo inviato via email per confermare il tuo account</h6></Link>
                  </div>
                  <div className="modal-footer" style={{height: '50px'}}>
                  </div>
              </div>
            </div>
          </div>

        {!confirmRegistered && <div className='row'>
        <div className='col-12 col-md-8 offset-md-2 col-lg-5 m-lg-auto p-2'>
            <div className='mt-5'>
                <form className='wl-form rounded shadow p-2'>
                <h4 className='h4 text-center mt-3 mb-5'>Registrazione {owner === 'admin' ? 'utente' : 'venditore'}:</h4>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="username">Email (username):</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='username' value={newUserData.username} className={'form-control' + (!validation['username'].valid && validation['username'].touched ? ' is-invalid': '')} name='username' placeholder='Email...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['username'].valid || !validation['username'].touched ? ' d-none': '')}>{validation['username'].message}</span>
                    </div>
                </div>

                {owner === 'admin' && <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="role">Ruolo:</label>
                    </div>
                    <div className='col-8'>
                    <select value={newUserData.role} className={'form-select' + (!validation['role'].valid && validation['role'].touched ? ' is-invalid': '')} name='role' placeholder='Email...' onChange={(e)=>{handleUserChange(e)}} >
                        <option value=''>Seleziona...</option>
                        <option value='seller'>Venditore</option>
                        <option value='admin'>Amministratore</option>
                    </select>
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['role'].valid || !validation['role'].touched ? ' d-none': '')}>{validation['role'].message}</span>
                    </div>
                </div>}

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="firstname">Nome:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='firstname' value={newUserData.firstname} className={'form-control' + (!validation['firstname'].valid && validation['firstname'].touched ? ' is-invalid': '')} name='firstname' placeholder='Nome...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['firstname'].valid || !validation['firstname'].touched ? ' d-none': '')}>{validation['firstname'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="lastname">Cognome:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='lastname' value={newUserData.lastname} className={'form-control' + (!validation['lastname'].valid && validation['lastname'].touched ? ' is-invalid': '')} name='lastname' placeholder='Cognome...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['lastname'].valid || !validation['lastname'].touched ? ' d-none': '')}>{validation['lastname'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="telephone">Telefono:</label>
                    </div>
                    <div className='col-8'>
                    <input type='text' autoComplete='telephone' value={newUserData.telephone} className={'form-control' + (!validation['telephone'].valid && validation['telephone'].touched ? ' is-invalid': '')} name='telephone' placeholder='Telefono...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['telephone'].valid || !validation['telephone'].touched ? ' d-none': '')}>{validation['telephone'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="adult">Sono maggiorenne:</label>
                    </div>
                    <div className='col-8 d-flex align-items-center'>
                    <input type='checkbox' value={newUserData.adult} className={'mcr-checkbox-wine' + (!validation['adult'].valid && validation['adult'].touched ? ' is-invalid': '')} name='adult' placeholder='Cognome...' onChange={(e)=>{handleUserChange(e)}} />
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['adult'].valid || !validation['adult'].touched ? ' d-none': '')}>{validation['adult'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="password">Password:</label>
                    </div>

                    <div className='col-8'>
                        <div className='input-group'>
                            <input type={passwordInputType} autoComplete='new-password' value={newUserData.password} className={'form-control' + (!validation['password'].valid && validation['password'].touched ? ' is-invalid': '')} name='password' placeholder='Password...' onChange={(e)=>{handleUserChange(e)}} />
                            <div className='input-group-text' style={{cursor: 'pointer'}} onClick={()=>{setPasswordInputType(passwordInputType==='password'?'text':'password')}}>
                                <span className="material-symbols-rounded text-red">{passwordInputType==='password'?'visibility':'visibility_off'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['password'].valid || !validation['password'].touched ? ' d-none': '')}>{validation['password'].message}</span>
                    </div>

                    <div className='col-8 offset-4 mt-3'>
                        <div className='input-group'>
                            <input type={passwordInputType} autoComplete='new-password' value={newUserData.password2} className={'form-control' + (!validation['password2'].valid && validation['password2'].touched ? ' is-invalid': '')} name='password2' placeholder='Ripeti Password...' onChange={(e)=>{handleUserChange(e)}} />
                            <div className='input-group-text' style={{cursor: 'pointer'}} onClick={()=>{setPasswordInputType(passwordInputType==='password'?'text':'password')}}>
                                <span className="material-symbols-rounded text-red">{passwordInputType==='password'?'visibility':'visibility_off'}</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['password2'].valid || !validation['password2'].touched ? ' d-none': '')}>{validation['password2'].message}</span>
                    </div>
                </div>

                <div className='row mt-3 mb-2'>
                    <div className='col-4 d-flex align-items-center'>
                    <label className="form-label" htmlFor="terms_and_conditions">Accetto termini e condizioni:</label>
                    </div>
                    <div className='col-8 d-flex align-items-center'>
                    <input type='checkbox' value={newUserData.terms_and_conditions} className={'mcr-checkbox-wine' + (!validation['terms_and_conditions'].valid && validation['terms_and_conditions'].touched ? ' is-invalid': '')} name='terms_and_conditions' placeholder='Cognome...' onChange={(e)=>{handleUserChange(e)}} />
                    <a className='even-link ms-3' style={{textDecoration: 'underline'}} href="https://www.hellobacco.com/content/legal-notice-2" target="_blank" rel="noreferrer">Vedi Termini e condizioni</a>
                    </div>
                    <div className='col-12'>
                        <span className={'text-even float-end' + (validation['terms_and_conditions'].valid || !validation['terms_and_conditions'].touched ? ' d-none': '')}>{validation['terms_and_conditions'].message}</span>
                    </div>
                </div>

                <div className='form-footer mt-4 pb-3'>
                <button className='btn mcr-btn danger btn-with-icon ms-0' title="Annulla" onClick={() => {cancelRegistration()}} ><span className="material-symbols-rounded">close</span></button>
                <button className='btn mcr-btn success btn-with-icon ms-0 float-end' title="Salva" onClick={(e) => {handleSave(e);return false}} disabled={!canSubmitUser} ><span className="material-symbols-rounded">done</span></button>
                </div>

                </form>
            </div>
            </div>
        </div>}
     </div>
  </>
  )
}

export default Register
import { useSelector, useDispatch } from 'react-redux'
//import { ReactComponent as Logo } from '../Logo_Giappichelli.svg'
import { logOut, setShow } from '../app/store'
import { useNavigate, NavLink } from 'react-router-dom'
import useWindowSize from '../hooks/Windowsize'
import { Tooltip } from '@mui/material'

const Topbar = (props) => {

    const menu = useSelector((state)=> state.menu)
    const user = useSelector((state)=> state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {url,nomePlur} = props
    const windowSize = useWindowSize()

    const handleMenuButtonClick = () => {
      dispatch(setShow(!menu.show))
      if(!menu.show){
        navigate(url)
      }
    }
  
  return (
    <header className={(!user.logged_in || (user.logged_in && !user.capabilities.includes('cap_admin'))) ? 'header-seller':''} >
        <div className="container h-100 d-flex justify-content-between align-items-center">
            
            <div className='d-flex justify-content-center align-items-center'>
              {(user.logged_in && user.capabilities.includes('cap_admin')) && <span className='text-dark me-2 material-symbols-rounded menu-hamburger' onClick={()=>{handleMenuButtonClick()}}>{menu.show?'menu_open':'menu'}</span>}
              <div className='d-flex flex-column justify-content-center align-items-center'>
              <NavLink to={'/'}><img src={windowSize=== 'sm' ? process.env.REACT_APP_LOCAL_SUBFOLDER+"/img/hellobacco_logo.jpg" : process.env.REACT_APP_LOCAL_SUBFOLDER+"/img/hellobacco_logo.jpg"} alt="Winevalue logo" className="logo_img" />{/* <Logo alt="Logo" className="logo_img" /> */}</NavLink>
              <NavLink className='dark-link' style={{'fontSize': '0.7em'}} to="https://www.hellobacco.com/">Torna su hellobacco</NavLink>
              </div>
            </div>
            <div className="h-100 d-flex justify-content-end align-items-center">
                {/* <span className="material-symbols-rounded header-icon">person</span> */}
                {/* <span className='header-text me-3'>{user.logged_in ? 'Benvenuto Nome Cognome': 'Utente non loggato'}</span> */}
                {user.logged_in && user.capabilities.includes('cap_admin') && <p className={"mx-2 mb-0 mx-2 mb-0"+(windowSize === 'sm' ? ' header-user-icon' : ' header-text')}>
                  <Tooltip disableFocusListener enterTouchDelay={1} arrow title='Clicca qui per il tuo profilo'>
                    <NavLink className='dark-link' to='/profile' >{windowSize === 'sm' ? <span className="material-symbols-rounded">person</span> : user.firstname+' '+user.lastname}</NavLink>
                  </Tooltip>
                  {user.logged_in && nomePlur ? ' - Lista '+nomePlur:''}</p>}
                {(user.logged_in && !user.capabilities.includes('cap_admin')) && <p className={"mx-2 mb-0 mx-2 mb-0"+(windowSize === 'sm' ? ' header-user-icon' : ' header-text')}>
                  <Tooltip disableFocusListener enterTouchDelay={1} arrow title='Clicca qui per il tuo profilo'>
                    <NavLink className='dark-link' to='/profile' >{windowSize === 'sm' ? <span className="material-symbols-rounded">person</span> : user.firstname+' '+user.lastname}</NavLink>
                  </Tooltip>
                  </p>}
                {user.logged_in && <Tooltip disableFocusListener enterTouchDelay={1} arrow title='Clicca qui per effettuare il logout'>
                    <span className="material-symbols-rounded" onClick={()=>{dispatch(logOut());navigate(process.env.REACT_APP_LOCAL_SUBFOLDER+'/')}} style={{cursor: 'pointer'}}>logout</span>
                  </Tooltip>}
            </div>
        </div>

    </header>
  )
}

export default Topbar

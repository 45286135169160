import React from 'react';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentPage } from '../app/store'
import TableComponent from '../components/TableComponent'
import useWindowHeight from '../hooks/WindowHeight'
import { setShow } from '../app/store'
import { useParams, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Offerwizard from '../components/Offerwizard';
import { LOG_URL } from '../constants';

function Index(props) {

  const {nomePlur,url,apiModel,enableSave,enableNew,baseUrl,links,additionalFilter} = props

  const [freeSearchString,setFreeSearchString] = useState('')
  const [tmpString,setTmpString] = useState('')
  const [triggerReset,setTriggerReset] = useState(0)
  const windowHeight = useWindowHeight()
  const loadedCount = useSelector((state)=> state.loadedCount)
  const user = useSelector((state)=> state.user)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {id} = useParams()

  useEffect(()=>{
    setFreeSearchString(localStorage.getItem(apiModel+'_freeSearchString')?localStorage.getItem(apiModel+'_freeSearchString'):'')
    setTmpString(localStorage.getItem(apiModel+'_freeSearchString')?localStorage.getItem(apiModel+'_freeSearchString'):'')
  },[apiModel,dispatch])

  const handleFreeSearchValueChange = (event) => {
    setTmpString(event.target.value)
    // New data auto reloaded when free search string changes
  }

  // Adds a delay of 200 mseconds from end of typing free search string to actual API call
  useEffect(()=>{
    const t = setTimeout(()=>{
      setFreeSearchString(tmpString)
      if(loadedCount.loadedCount>0){
        dispatch(setCurrentPage(1))
      }
      localStorage.setItem(apiModel+'_freeSearchString',tmpString)
    },200)
    return () => {
      clearTimeout(t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tmpString,apiModel,dispatch])

    const handleTableReset = (event) => {
      setTriggerReset(triggerReset+1) // Triggers the table component functions to reset all filters and get data
      setFreeSearchString('') // This is done by useEffect tied to setTmpString, but setting here skips update timer
      setTmpString('')
      localStorage.setItem(apiModel+'_freeSearchString','')
    }
  
    return (
      <div className='w-100'>
        <ToastContainer
          className='toast-raised'
          position='bottom-right'
          theme='dark'
        />
        {(url !== '/dashboard') && <><div className='container-fluid'>

          <div className="row">
            
            <div className="mt-2 d-inline-block" style={id?{width: '400px'}:{width: 'calc(100% - 100px)'}}>
              <label className='form-label text-even h6'>Ricerca libera:</label>
              <div className={"input-group"+(id?" mb-1":" mb-2")}>
                <input type="text" readOnly={baseUrl===LOG_URL} value={tmpString} onChange={handleFreeSearchValueChange} className="form-control bg-dark text-even" style={{borderRight: 'none'}} placeholder={(baseUrl===LOG_URL)?"Non disponibile per questa schermata":"Cerca..."} aria-label="Cerca..." aria-describedby="search-icon"/>
                <span className="input-group-text material-symbols-rounded bg-dark text-even" id="search-icon">search</span>
              </div>
            </div>

            <div className={id?'':'d-inline-block px-0 freesearch-side-buttons-container'} style={id?{width: '100%'}:{width: '100px'}}>
            
              <button className='btn mcr-btn warning btn-with-icon' title='Reset filtri' onClick={()=>{handleTableReset()}}>
                <span className="material-symbols-rounded">filter_alt_off</span></button>
              
              {enableSave && enableNew && <button className='btn mcr-btn btn-with-icon' title='Nuovo' onClick={()=>{navigate(process.env.REACT_APP_LOCAL_SUBFOLDER+url+'/new')}}>
                <span className="material-symbols-rounded">add</span></button>}

            </div>

          </div>
        </div>
        <div className="container-fluid" style={{height: windowHeight - 300 + 'px'}}>
          {<TableComponent freeSearch={freeSearchString} nomePlur={nomePlur} apiModel={apiModel} url={url} baseUrl={baseUrl} triggerReset={triggerReset} additionalFilter={additionalFilter} />}
        </div></>}

        {(url === '/dashboard') && user.capabilities.includes('cap_offer_create_own') && <Offerwizard pimUrl={process.env.REACT_APP_LOCAL_BACKEND_URL} />}

        {(url === '/dashboard') && user.capabilities.includes('cap_admin') && <>
        <div className='hero d-flex justify-content-center align-items-center' style={{paddingBottom: 50}}>
          
          <div>
              <h3 className='h3 text-even mb-3'>Accesso rapido</h3>
              <ul className='ps-1 m-auto' style={{width: '160px', fontSize: '24px'}}>
            {links.map((link, index) => (
                (link.url !== '/register' && link.url !== '/dashboard') ? <li key={index} className='mt-2'>
                <NavLink to={link.url} className={'nav-link'} onClick={
                  ()=>{
                  dispatch(setShow(false))
                }
                  }>
                    <span className="material-symbols-rounded shift-down">{link.nome_icona}</span> {link.nome_link} 
                </NavLink>
                </li> : ''
            ))}
            </ul>
          </div>
        </div>
        </>}

      </div>
    );
  }

  export default Index;
  
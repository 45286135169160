import { configureStore, createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
  name: 'user',
  initialState: {
      logged_in: false,
      wrong_login: false,
      id: null,
      code: '',
      token: '',
      refreshToken: '',
      capabilities: [],
      expires: null,
      firstname: null,
      lastname: null,
      username: null
  },
  reducers: {
    setWrongLogin: (state,action) => {
      state.wrong_login = action.payload
      localStorage.setItem('wrong_login',state.wrong_login)
    },
    // Per salvare il codice inventato per la code_challenge prima di chiamare il server oauth
    setCode: (state,action) => {
      state.code = action.payload
      localStorage.setItem('login_code',action.payload)
    },
    setCapabilities: (state,action) => {
      state.capabilities = action.payload
      localStorage.setItem('capabilities',JSON.stringify(state.capabilities))
    },
    setUserId: (state,action) => {
      state.id = action.payload
      localStorage.setItem('user_id',action.payload)
    },
    setUserName: (state,action) => {
      state.firstname = action.payload.firstname
      state.lastname = action.payload.lastname
      state.username = action.payload.username
      localStorage.setItem('user_firstname',action.payload.firstname)
      localStorage.setItem('user_lastname',action.payload.lastname)
      localStorage.setItem('user_username',action.payload.username)
    },
    logIn: (state,action) => {
      state.logged_in=true
      state.wrong_login=false
      state.token=action.payload.token
      state.refreshToken=action.payload.refreshToken
      let date = new Date()
      if (!isNaN(parseInt(action.payload.expires))){
        date.setSeconds(date.getSeconds() + parseInt(action.payload.expires))
      }
      state.expires=date.getTime()
    },
    saveLoginInLocalStorage: state => {
      localStorage.setItem('logged_id',state.logged_in?'true':'false')
      localStorage.setItem('wrong_login',state.wrong_login?'true':'false')
      localStorage.setItem('token',state.token)
      localStorage.setItem('refreshToken',state.refreshToken)
      localStorage.setItem('expires',state.expires)
      // Qui non salviamo il login code perché dovrebbe essere salvato allo step precedente
    },
    loadLoginFromLocalStorage: state => {
      state.logged_in=localStorage.getItem('logged_id')==='true'?true:false
      state.wrong_login=localStorage.getItem('wrong_login')==='true'?true:false
      state.token=localStorage.getItem('token')
      state.refreshToken=localStorage.getItem('refreshToken')
      state.expires=localStorage.getItem('expires') ? parseInt(localStorage.getItem('expires')) : new Date().getTime()
      state.code=localStorage.getItem('login_code')
      if(state.logged_in){
        state.capabilities=JSON.parse(localStorage.getItem('capabilities'))
        state.id = localStorage.getItem('user_id')
        state.firstname = localStorage.getItem('user_firstname')
        state.lastname = localStorage.getItem('user_lastname')
        state.username = localStorage.getItem('user_username')
      }
    },
    logOut: state => {
      state.logged_in=false
      state.token=''
      state.refreshToken=''
      state.expires=null
      state.code=''
      state.capabilities=[]
      state.id=null
      state.firstname=null
      state.lastname=null
      state.username=null
      localStorage.setItem('logged_id','false')
      localStorage.setItem('token','')
      localStorage.setItem('refreshToken','')
      localStorage.setItem('expires',null)
      localStorage.setItem('login_code','')
      localStorage.setItem('capabilities','[]')
      localStorage.setItem('user_id',null)
      localStorage.setItem('user_firstname',null)
      localStorage.setItem('user_lastname',null)
      localStorage.setItem('user_username',null)
    }
  }
})

const tableDataSlice = createSlice({
  name: 'data',
  initialState: {tableData: null},
  reducers: {
    setData: (state,action) => {
      state.tableData=action.payload
    }
  }
})
const dataStructureSlice = createSlice({
  name: 'dataStructure',
  initialState: {
    structure: {},
    jsonMaxSize: 0,
    serviceCode: '',
    relatedUrls: {}
  },
  reducers: {
    setDataStructure: (state,action) => {
      state.structure=action.payload
    },
    setJsonMaxSize: (state,action) => {
      state.jsonMaxSize=action.payload
    },
    setServiceCode: (state,action) => {
      state.serviceCode=action.payload
    },
    setRelatedUrls: (state,action) => {
      state.relatedUrls = action.payload
    }
  }
})
const uiStructureSlice = createSlice({
  name: 'uiStructure',
  initialState: {structure: null},
  reducers: {
    setUiStructure: (state,action) => {
      /* let strar = []
      for(let k in action.payload){
        strar.push(action.payload[k])
        action.payload[k].section=true
      } */
      state.structure=action.payload
    }
  }
})

// Tracks number of times data has been loaded, not number of rows
const dataLoadedCountSlice = createSlice({
  name: 'loadedCount',
  initialState: {
    loadedCount: 0,
    triggerReload: 0
  },
  reducers: {
    setLoadedCount: (state,action) => {
      state.loadedCount=action.payload
    },
    increaseLoadedCount: (state) => {
      state.loadedCount=state.loadedCount+1 
    },
    decreaseLoadedCount: (state) => {
      state.loadedCount=state.loadedCount-1 
    },
    setTriggerReload: (state,action) => {
      state.triggerReload=action.payload
    }
  }
})

const menuSlice = createSlice({
  name: 'menu',
  initialState: {show: false},
  reducers: {
    setShow: (state,action) => {
      state.show=action.payload
    }
  }
})

const paginationSlice = createSlice({
  name: 'pagination',
  initialState: {
    recordCount: 0,
    pageCount: 1,
    currentPage: 1,
    recordsPerPage: null
  },
  reducers: {
    setPagination: (state,action) => {
      state.recordCount = action.payload.recordCount
      state.pageCount = action.payload.pageCount
      state.currentPage = action.payload.currentPage
      state.recordsPerPage = action.payload.recordsPerPage
    },
    setRecordCount: (state,action) => {
      state.recordCount=action.payload
    },
    setPageCount: (state,action) => {
      state.pageCount=action.payload
    },
    setCurrentPage: (state,action) => {
      state.currentPage=action.payload
    },
    decreaseCurrentPage: (state) => {
      state.currentPage=(state.currentPage>1)?(state.currentPage-1):(state.currentPage)
    },
    increaseCurrentPage: (state) => {
      state.currentPage=(state.currentPage<state.pageCount)?(state.currentPage+1):(state.currentPage)
    },
    setrecordsPerPage: (state,action) => {
      state.recordsPerPage=action.payload
    },
  }
})

const newOfferSlice = createSlice({
  name: 'newOffer',
  initialState: {
    started: false,
    saved: false,
    step: 1,
  },
  reducers: {
    setStarted: (state, action) => {
      state.started = action.payload
    },
    setSaved: (state, action) => {
      state.saved = action.payload
    },
    setStep: (state, action) => {
      state.step = action.payload
    },
  }
})

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    data: tableDataSlice.reducer,
    dataStructure: dataStructureSlice.reducer,
    menu: menuSlice.reducer,
    loadedCount: dataLoadedCountSlice.reducer,
    pagination: paginationSlice.reducer,
    uiStructure: uiStructureSlice.reducer,
    /* newoffer */
    newOffer: newOfferSlice.reducer
  },
});

export const {logIn,saveLoginInLocalStorage,loadLoginFromLocalStorage,logOut,setWrongLogin,setCapabilities,setCode,setUserId,setUserName} = userSlice.actions
export const {setData} = tableDataSlice.actions
export const {setShow} = menuSlice.actions
export const {setDataStructure,setJsonMaxSize,setServiceCode,setRelatedUrls} = dataStructureSlice.actions
export const {setUiStructure} = uiStructureSlice.actions
export const {setLoadedCount,increaseLoadedCount,decreaseLoadedCount,setTriggerReload} = dataLoadedCountSlice.actions
export const {setPagination,setRecordCount,setPageCount,setCurrentPage,decreaseCurrentPage,increaseCurrentPage,setrecordsPerPage} = paginationSlice.actions
export const {setStarted,setSaved,setStep} = newOfferSlice.actions

export default store;

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { maxFrontendUploadSize } from '../constants'
import axios from 'axios';

const FileUpload = (props) => {

  const {fileName,apiSubModel,dependent,value,field,className,makeDisable,MEDIA_URL,changeHandler} = props

  const [oldFileName, setoldFileName] = useState('')

  const maxUploadSize = useSelector((state)=> state.dataStructure.jsonMaxSize>maxFrontendUploadSize?maxFrontendUploadSize:state.dataStructure.jsonMaxSize)
  const user = useSelector((state)=> state.user)

  useEffect(()=>{
    const fetchUuidOnLoad = async () => {
      const url = MEDIA_URL + apiSubModel + '/load'
      const config = {
          headers: {'Authorization': 'Bearer ' + user.token},
          params: {}
      }
      if(value){
        let requestBody=[value]
        const response = await axios.post(url,requestBody,config)
        const recdata = response.data.result[value] // value is uuid
        if(recdata && recdata.uuid && recdata.filename){
          setoldFileName(recdata.filename)
        }
      }
    }
    fetchUuidOnLoad()
  },[apiSubModel, value, user.token, MEDIA_URL, fileName])

  const handleChange = (target,field) => {
    if(target.files && target.files[0]){
        let chunkArray = [],
            fr = new FileReader()
        fr.onload=function(readresult) {
            let maxsize= Math.floor((maxUploadSize - "{\"chunk\":\"\"}".length)/1.34)
            // read chunks
            for(let i=0;i<target.files[0].size && maxsize;i+=maxsize){
                let chunk=atob(btoa(readresult.target.result.substring(readresult.target.result.indexOf(',') + 1)).substring(i,i+maxsize))
                chunkArray.push(chunk)
                maxsize = chunk.length
	          }
            let fileInfo = {
                field_name: field.name,
                field_model: apiSubModel,
                old_uuid: value,
                oldFileName: oldFileName,
                newFileName: target.files[0].name,
                mimetype: target.files[0].type,
                dependent: dependent
            }
            let chunkData = {
              field_name: field.name,
              chunks: chunkArray
            }
            changeHandler(value,field,chunkData,fileInfo)
        }
        fr.readAsDataURL(target.files[0])
    }
}

  return (
    <>
    <div className={"form-control" + (makeDisable ? ' disabled' : '')} style={{position: 'relative', height: 38}}>
      {fileName ? fileName : 'Scegli file'}
      <input
          type='file'
          disabled={makeDisable}
          name={field.name}
          required={field.required}
          className={className}
          onChange={(e) => { handleChange(e.target, field) }} 
      />
    </div>
    {!fileName && <p className='text-even mt-2'>File sul server: {makeDisable ? (oldFileName ? oldFileName : 'Nessuno') : (<a 
    className='even-link' href={oldFileName ? (MEDIA_URL + apiSubModel + '/download/' + value) : '#'} target='_blank' rel='noreferrer' >{oldFileName ? oldFileName : 'Nessuno'}</a>)}
    </p>}
    </>
  )

}

export default FileUpload

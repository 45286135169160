import React from 'react'

const SinglePhoto = (props) => {

  const {url,width,height,className,position,uuid,handleDelete,handleSort,mouseOver,makeDisable} = props

  const handleDelBtnClick = (e,uuid) => {
    e.preventDefault()
    handleDelete(uuid)
  }

  const handleDoubleClick = (e,uuid) => {
    e.preventDefault()
    if(!makeDisable){
      handleSort(uuid)
    }
  }

  return (
    <div className={'d-inline-block'} style={{position: 'relative'}} >
    <img
      className={className + (position===0 ? ' border-danger border-4' : '')}
      style={position !== 0 ? {cursor: 'pointer'} : {}}
      src={url} width={width}
      height={height} alt='Immagine'
      title={position !== 0 ? 'Doppio clic: imposta copertina' : ''}
      onDoubleClick={(e)=>(handleDoubleClick(e,uuid))}
    />
      {!makeDisable && <button className={'btn mcr-btn danger btn-micro-with-icon' + (mouseOver ? ' photo-button-delete' : ' d-none')} title="Elimina" onClick={(e) => {handleDelBtnClick(e,uuid)}} ><span className="material-symbols-rounded">delete</span></button>}
    </div>
  )
}

export default SinglePhoto
